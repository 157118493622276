import revive_payload_client_nEheheVOAZ from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_d5NHwr3DV9 from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_yXvt7cMEf5 from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_JfmyzKgrRN from "/usr/src/app/node_modules/.pnpm/nuxt-site-config@2.2.21_magicast@0.3.5_rollup@4.26.0_vite@5.4.11_@types+node@22.9.0_sass@1.80_2yihngvhld7ya7o35ysr4du2dy/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_sEX6E77kxB from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_pjT142qwmD from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7RKGYfDILo from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import restore_state_client_DlEht0rHip from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/restore-state.client.js";
import chunk_reload_client_YrPJGw9Tsb from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_je2X40Zq3N from "/usr/src/app/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.5.0_@types+node@22.9.0_ioredis@5.4.1_magicast@0.3.5_rollup@4._2i3vh32ood5d2tjw6lkitefduy/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import _00_init_client_77O4tQBcSo from "/usr/src/app/plugins/00.init.client.ts";
import _01_auth_client_UOBMAirzzr from "/usr/src/app/plugins/01.auth.client.ts";
import _03_vuetify_hLVW487wAp from "/usr/src/app/plugins/03.vuetify.ts";
export default [
  revive_payload_client_nEheheVOAZ,
  unhead_d5NHwr3DV9,
  router_yXvt7cMEf5,
  _0_siteConfig_JfmyzKgrRN,
  payload_client_sEX6E77kxB,
  navigation_repaint_client_pjT142qwmD,
  check_outdated_build_client_7RKGYfDILo,
  restore_state_client_DlEht0rHip,
  chunk_reload_client_YrPJGw9Tsb,
  components_plugin_KR1HBZs4kY,
  prefetch_client_je2X40Zq3N,
  _00_init_client_77O4tQBcSo,
  _01_auth_client_UOBMAirzzr,
  _03_vuetify_hLVW487wAp
]